<template>
  <div id="app">
    <Header></Header>
    <Currently></Currently>
    <History></History>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Currently from './components/Currently.vue'
import History from './components/History.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header, Currently, History, Footer
  }
}
</script>

<style scoped>
   @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600&display=swap');

#app {
    font-family: 'Montserrat', sans-serif;
}
</style>
