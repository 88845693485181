<template>
  <div id="header">
    <h1 id="title">Wetter</h1>
  </div>
</template>

<script>
  export default {
  }
</script>

<style scoped>
  #header {
    width: 100%;
    height: auto;
    background-color: rgb(20, 20, 20);
}

#title {
    text-align: center;
    color: rgb(240, 240, 240);
    padding-top: 18px;
    font-size: 55px;
    margin: 0px;
    padding-bottom: 17px;
    font-weight: 300;
}
</style>