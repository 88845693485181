<template>
  <footer>
   <p id="footer">SPA 2021 - 💪 made by Jan Fäh</p>
  </footer>
</template>

<script>
  export default {
  }
</script>

<style scoped>
  #footer {
    background: rgb(20, 20, 20);
    text-align: center;
    color: rgb(240, 240, 240);
    font-size: 15px;
    width: 100%;
    height: auto;
    margin: 0px;
 }
</style>