<template>
  <div class="currently">
    <h3 class="currently-title">Aktuelles Wetter</h3>
    <div class="container">
      <div class="row">
        <div class="four columns">
          <div class='chart_1'>
            <p class="percent percent-temp"><span id="temperatur"></span>°C</p>
            <canvas class="zeiger" id="my_chart2" height="400px" width="400px"></canvas>
            <canvas class="chart" id="my_chart1" height="400px" width="400px"></canvas>
            <p class="text-halbkreis_diagramme">Temperatur</p>
          </div>
        </div>
        <div class="four columns">
          <div class="chart_2">
            <p class="percent"><span id="humidity"></span>%</p>
            <canvas class="zeiger" id="my_chart4" height="400px" width="400px"></canvas>
            <canvas class="chart" id="my_chart3" height="400px" width="400px"></canvas>
            <p class="text-halbkreis_diagramme">Luftfeuchtigkeit</p>
          </div>
        </div>
        <div class="four columns">
          <div class="chart_3">
            <p class="percent percent-luftdruck"><span id="pressure"></span>hPa</p>
            <canvas class="zeiger" id="my_chart6" height="400px" width="400px"></canvas>
            <canvas class="chart" id="my_chart5" height="400px" width="400px"></canvas>
            <p class="text-halbkreis_diagramme">Luftdruck</p>
          </div>
        </div>
        <div class="row">
          <div class="six columns">
            <div class="chart_4">
              <p class="percent percent-windgeschw"><span id="windSpeed"></span>km/h</p>
              <canvas class="zeiger" id="my_chart8" height="400px" width="400px"></canvas>
              <canvas class="chart" id="my_chart7" height="400px" width="400px"></canvas>
              <p class="text-halbkreis_diagramme">Wind</p>
            </div>
          </div>
          <div class="six columns">
            <div class="chart_5">
              <p class="percent percent-sichtweite"><span id="visibility"></span>km</p>
              <canvas class="zeiger" id="my_chart10" height="400px" width="400px"></canvas>
              <canvas class="chart" id="my_chart9" height="400px" width="400px"></canvas>
              <p class="text-halbkreis_diagramme">Sichtweite</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js'
import axios from 'axios'

export default {
  name: "Currently",

  data() {
    return {
      temperatur: null,
      pressure: null,
      humidity: null,
      windSpeed: null,
      visibility: null,
    }
  },
  mounted() {

    //this.fetch();
    this.getData();
  },
  methods: {
    updateCharts() {
      const green = 'rgba(0, 200, 0, 1)';
      const yellow = 'rgba(255, 127, 36, 1)';
      const red = 'rgba(238, 59, 59, 1)';
      const blue = 'rgba(72, 118, 255, 1)';

      const myChartObject1 = document.getElementById('my_chart1');
      new Chart(myChartObject1, this.getBackgroundChart(3, blue, 2, green, 1, red));

      const myChartObject2 = document.getElementById('my_chart2');
      new Chart(myChartObject2, this.getValueChart(-30, 40, this.temperatur));

      const myChartObject3 = document.getElementById('my_chart3');
      new Chart(myChartObject3, this.getBackgroundChart(1, yellow, 1, green, 1, blue));

      const myChartObject4 = document.getElementById('my_chart4');
      new Chart(myChartObject4, this.getValueChart(0, 100, this.humidity));

      const myChartObject5 = document.getElementById('my_chart5');
      new Chart(myChartObject5, this.getBackgroundChart(1, red, 1, yellow, 1, green));

      const myChartObject6 = document.getElementById('my_chart6');
      new Chart(myChartObject6, this.getValueChart(950, 1050, this.pressure));

      const myChartObject7 = document.getElementById('my_chart7');
      new Chart(myChartObject7, this.getBackgroundChart(1, green, 2, yellow, 3, red));

      const myChartObject8 = document.getElementById('my_chart8');
      new Chart(myChartObject8, this.getValueChart(0, 120, this.windSpeed));

      const myChartObject9 = document.getElementById('my_chart9');
      new Chart(myChartObject9, this.getBackgroundChart(1, red, 2, yellow, 3, green));

      const myChartObject10 = document.getElementById('my_chart10');
      new Chart(myChartObject10, this.getValueChart(0, 50, this.visibility));
    },
    getValueChart(min, max, value) {
      return {
        type: 'doughnut',
            data: {
                labels: ['', 'Zeiger', ''],
                datasets: [{
                    data: this.getValueArray(min, max, value),
                    backgroundColor: [
                        'rgba(159, 90, 253, 0)',
                        'rgba(0, 0, 0, 1)',
                        'rgba(159, 90, 253, 0)'
                    ],
                    borderWidth: 0
                }]
            },
            options: {
                rotation: 1 * Math.PI, /** This is where you need to work out where 89% is */
                circumference: 1 * Math.PI, /** put in a much smaller amount  so it does not take up an entire semi circle */
                legend: {
                    display: false
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem) {
                              return tooltipItem.yLabel;
                        }
                    }
                },
                cutoutPercentage: 40
            }
      }
    },
    getBackgroundChart(sLeft, cLeft, sCenter, cCenter, sRight, cRight) {
      return {
        type: 'doughnut',
        data: {
            datasets: [{
                data: [sLeft, sCenter, sRight],
                backgroundColor: [cLeft, cCenter, cRight],
                borderColor: [
                    'rgba(50, 50, 50, 1)',
                    'rgba(50, 50, 50, 1)',
                    'rgba(50, 50, 50, 1)'
                ],

                borderWidth: 2
            }],
            borderWidth: 0
        },

        options: {
            rotation: 1 * Math.PI,
            circumference: 1 * Math.PI, 
            legend: {
                display: false
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem) {
                            return tooltipItem.yLabel;
                    }
                }
            },
            cutoutPercentage: 90
        }
      }
    },
    getValueArray(min, max, value) {
      const x = 100 / (max - min) * (value - min)
      console.log(min, max, value, x)
      return [x-1, 2, 98 - (x - 1)]
    },
    fetch() {
      axios.get('/forecast/25e7a48aa7fbf4c04b826331562ee4ab/47.4156,8.58897?units=si&lang=de&exclude=hourly,flags').then(response => {
        console.log('+++ SUCCESS +++');
        console.log(response.data);
        const rawData = response.data
        console.log("---> ", rawData);
        this.temperatur = Math.round((rawData['currently']['temperature'] + Number.EPSILON ) * 10 ) / 10
        this.humidity = Math.round(rawData['currently']['humidity'] * 100)
        this.pressure = Math.round(rawData['currently']['pressure'])
        this.windSpeed = Math.round(rawData['currently']['windSpeed'])
        this.visibility = Math.round(rawData['currently']['visibility'])
        document.getElementById("temperatur").innerHTML = this.temperatur;
        document.getElementById("humidity").innerHTML = this.humidity;
        document.getElementById("pressure").innerHTML = this.pressure;
        document.getElementById("windSpeed").innerHTML = this.windSpeed;
        document.getElementById("visibility").innerHTML = this.visibility;     
        this.updateCharts()
      }).catch(() => {
        console.log('--- ERROR ---');
      });
    },
    getData() {
      const rawData = {
        "latitude": 47.4156,
        "longitude": 8.58897,
        "timezone": "Europe/Zurich",
        "currently": {
            "time": 1617371322,
            "summary": "Überwiegend bewölkt",
            "icon": "partly-cloudy-day",
            "precipIntensity": 0.0496,
            "precipProbability": 0.01,
            "precipType": "rain",
            "temperature": 18.67,
            "apparentTemperature": 18.67,
            "dewPoint": 8.29,
            "humidity": 0.51,
            "pressure": 1015.3,
            "windSpeed": 2.27,
            "windGust": 4.22,
            "windBearing": 18,
            "cloudCover": 0.62,
            "uvIndex": 2,
            "visibility": 16.093,
            "ozone": 372
        },
        "offset": 2
      }
      this.temperatur = Math.round((rawData['currently']['temperature'] + Number.EPSILON ) * 10 ) / 10
      this.humidity = Math.round(rawData['currently']['humidity'] * 100)
      this.pressure = Math.round(rawData['currently']['pressure'])
      this.windSpeed = Math.round(rawData['currently']['windSpeed'])
      this.visibility = Math.round(rawData['currently']['visibility'])
      document.getElementById("temperatur").innerHTML = this.temperatur;
      document.getElementById("humidity").innerHTML = this.humidity;
      document.getElementById("pressure").innerHTML = this.pressure;
      document.getElementById("windSpeed").innerHTML = this.windSpeed;
      document.getElementById("visibility").innerHTML = this.visibility;
      this.updateCharts()
    },
  }
};


</script>

<style scoped>
.currently-title {
  font-weight: 500;
  color: rgb(240, 240, 240);
  text-align: center;
  padding-top: 100px;
  margin-bottom: 0px;
  padding-bottom: 80px;
  font-weight: 500;
}

.zeiger {
    position: absolute;
}

.percent {
    position: absolute;
    z-index: 3;
    color:rgb(240, 240, 240);
    font-size: 265%;
    padding-left: 40%;
    padding-top: 58%;
}

.chart_1 {
    position: relative;
}

.percent-temp {
    font-size: 37px;
    padding-left: 33%;
    padding-top: 59%;
}

.chart_2 {
    position: relative;
}

.chart_3 {
    position: relative;
}

.percent-luftdruck {
    font-size: 35px;
    padding-left: 30%;
}

.text-halbkreis_diagramme {
    color: rgb(240, 240, 240);
    text-align: center;
    margin-top: -45px;
    font-weight: 400;
    font-size: 31px;
}

.chart_4 {
    padding-left: 35%;
}

.percent-windgeschw {
    padding-left: 10%;
    padding-top: 19%;
    font-size: 35px;
}

.percent-sichtweite {
    padding-left: 11%;
    padding-top: 19%;
    font-size: 35px;
}

.chart_4, .chart_5 {
    max-width: 65%;
}

.unter-title {
    font-weight: 500;
    color: rgb(240, 240, 240);
    text-align: center;
    padding-top: 150px;
    margin-bottom: 0px;
    padding-bottom: 80px;
}


.akt_wetter {
    padding-top: 220px;
}


</style>