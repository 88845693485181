<template>
  <div class="history">
    <h3 class="history-title">Historie</h3>
    <div class="picker">
      <p class="picker-text">Von:</p>
      <datepicker style="text-align: center;" v-model="picked" 
        :locale="locale" :upperLimit="new Date()" :inputFormat="format"/>
      <button class="picker-button" @click="loadHistory()">OK</button>
      <p class="picker-text">bis 7 Tage vorher</p>
    </div>
    <div class="container">
      <div class="row">
        <div class="four columns">
          <canvas class="line_chart1" id="line_chart1" width="400" height="400"></canvas>
        </div>
        <div class="four columns">
          <canvas class="line_chart2" id="line_chart2" width="400" height="400"></canvas>
        </div>
        <div class="four columns">
          <canvas class="line_chart3" id="line_chart3" width="400" height="400"></canvas>
        </div>
      </div>
      <div class="row">
        <div class="six columns">
          <canvas class="line_chart4" id="line_chart4" width="400" height="400"></canvas>
        </div>
        <div class="six columns">
          <canvas class="line_chart5" id="line_chart5" width="400" height="400"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js'
//import axios from 'axios'
import Datepicker from 'vue3-datepicker'
import { de } from 'date-fns/locale'

  export default {
    name: "History",
    components: {
      Datepicker
    },

    data() {
      return {
        date: null,
        temperaturHigh: null,
        temperaturLow: null,
        pressure: null,
        humidity: null,
        windSpeed: null,
        visibility: null,
        picked: new Date('2021-05-15'),
        format: "dd.MM.yyyy",
        locale: de,
      }
    },
    mounted() {
    this.fetch();

    },
    methods: {
      loadHistory() {
        this.deleteCharts()
        this.fetch()
      },
      deleteCharts() {
        this.temperaturHigh = null
        this.temperaturLow = null
        this.pressure = null
        this.humidity = null
        this.windSpeed = null
        this.visibility = null
        this.updateCharts()
      },
      updateCharts() {
        const myChartObject11 = document.getElementById('line_chart1');
        new Chart(myChartObject11, this.getDoubleLineChart('Höchst-Temperatur in °C', -30, 40, this.temperaturHigh,"Tiefst-Temperatur in °C",this.temperaturLow));

        const myChartObject12 = document.getElementById('line_chart2');
        new Chart(myChartObject12, this.getLineChart('Ø Luftfeuchtigkeit in %', 0, 100, this.humidity));

        const myChartObject13 = document.getElementById('line_chart3');
        new Chart(myChartObject13, this.getLineChart('Ø Luftdruck in hPa', 950, 1050, this.pressure));

        const myChartObject14 = document.getElementById('line_chart4');
        new Chart(myChartObject14, this.getLineChart('Ø Windgeschw. in km/h', 0, 120, this.windSpeed));

        const myChartObject15 = document.getElementById('line_chart5');
        new Chart(myChartObject15, this.getLineChart('Ø Sichtweite in km', 0, 50, this.visibility));
      },
      getLineChart(label, min, max, values) {
        return {
          type: 'line',
          data: {
              labels: this.date,
              datasets: [{
                  label: [label],
                  data: values,
                  fill: false,
                  borderColor: 'rgba(30, 144, 255, 0.5)',
                  //#tension: 0
                  color: 'rgba(240, 240, 240, 1)'
              }]
          },
          options: {
              legend: {
                  labels: {
                      fontColor: "rgba(200, 200, 200, 0.4)",
                      fontSize: 18
                  }
              },
              scales: {
                  xAxes: [{
                      gridLines: {
                          color: "rgba(200, 200, 200, 0.4)"
                      },
                      ticks: {
                          fontColor: 'rgba(200, 200, 200, 0.4)'
                      }
                  }],
                  yAxes: [{
                      gridLines: {
                          color: "rgba(200, 200, 200, 0.4)"
                      },
                      ticks: {
                          min: min,
                          max: max,
                          fontColor: 'rgba(200, 200, 200, 0.4)'
                      }
                  }]
              }
          }
        }
      },
      getDoubleLineChart(label, min, max, values, label2, values2) {
        return {
          type: 'line',
          data: {
              labels: this.date,
              datasets: [{
                  label: [label],
                  data: values,
                  fill: false,
                  borderColor: 'rgba(230, 14, 15, 0.5)',
                  //#tension: 0
                  color: 'rgba(240, 240, 240, 1)'
              },{
                  label: [label2],
                  data: values2,
                  fill: false,
                  borderColor: 'rgba(30, 144, 255, 0.5)',
                  //#tension: 0
                  color: 'rgba(240, 240, 240, 1)'
              }]
          },
          options: {
              legend: {
                  labels: {
                      fontColor: "rgba(200, 200, 200, 0.4)",
                      fontSize: 18
                  }
              },
              scales: {
                  xAxes: [{
                      gridLines: {
                          color: "rgba(200, 200, 200, 0.4)"
                      },
                      ticks: {
                          fontColor: 'rgba(200, 200, 200, 0.4)'
                      }
                  }],
                  yAxes: [{
                      gridLines: {
                          color: "rgba(200, 200, 200, 0.4)"
                      },
                      ticks: {
                          min: min,
                          max: max,
                          fontColor: 'rgba(200, 200, 200, 0.4)'
                      }
                  }]
              }
          }
        }
      },
      fetch() {
        const secondsPerDay = 24 * 3600
        const endDay = Math.round(this.picked.getTime() / 1000) - secondsPerDay

        let times = []
        for (let step = endDay; step > endDay - 7 * secondsPerDay; step -= secondsPerDay) {
          times.push(step)
        }
        
        var date = []
        var temperaturHigh = []
        var temperaturLow = []
        var humidity = []
        var pressure = []
        var windSpeed = []
        var visibility = []

        // let responses = []
        // console.log(times)
        // async function getWeather(object, times) {
        //   try {

        //     responses[0] = await axios.get('/forecast/25e7a48aa7fbf4c04b826331562ee4ab/47.4156,8.58897,' + times[6] + '?units=si&lang=de&exclude=hourly,flags');
        //     responses[1] = await axios.get('/forecast/25e7a48aa7fbf4c04b826331562ee4ab/47.4156,8.58897,' + times[5] + '?units=si&lang=de&exclude=hourly,flags');
        //     responses[2] = await axios.get('/forecast/25e7a48aa7fbf4c04b826331562ee4ab/47.4156,8.58897,' + times[4] + '?units=si&lang=de&exclude=hourly,flags');
        //     responses[3] = await axios.get('/forecast/25e7a48aa7fbf4c04b826331562ee4ab/47.4156,8.58897,' + times[3] + '?units=si&lang=de&exclude=hourly,flags');
        //     responses[4] = await axios.get('/forecast/25e7a48aa7fbf4c04b826331562ee4ab/47.4156,8.58897,' + times[2] + '?units=si&lang=de&exclude=hourly,flags');
        //     responses[5] = await axios.get('/forecast/25e7a48aa7fbf4c04b826331562ee4ab/47.4156,8.58897,' + times[1] + '?units=si&lang=de&exclude=hourly,flags');
        //     responses[6] = await axios.get('/forecast/25e7a48aa7fbf4c04b826331562ee4ab/47.4156,8.58897,' + times[0] + '?units=si&lang=de&exclude=hourly,flags');
        //     console.log('+++ SUCCESS +++');
        //     responses.forEach(response => {
        //       console.log(response.data)
        //       const dailyData = response.data['daily']['data'][0]
        //       console.log('-----------> ', dailyData)
        //       console.log('-----------> ', format(new Date(dailyData['time'] * 1000),'dd.MM.yyyy'))
        //       date.push(format(new Date(dailyData['time'] * 1000),'dd.MM.yyyy'));
        //       temperaturHigh.push(Math.round((dailyData['temperatureHigh'] + Number.EPSILON ) * 10 ) / 10);
        //       temperaturLow.push(Math.round((dailyData['temperatureLow'] + Number.EPSILON ) * 10 ) / 10);
        //       humidity.push(Math.round(dailyData['humidity'] * 100));
        //       pressure.push(Math.round(dailyData['pressure']));
        //       windSpeed.push(Math.round(dailyData['windSpeed']));
        //       visibility.push(Math.round(dailyData['visibility']));
        //     });
        //     console.log(date)
        //     console.log(temperaturHigh)
        //     console.log(temperaturLow)
        //     console.log(humidity)
        //     console.log(pressure)
        //     console.log(windSpeed)
        //     console.log(visibility)

        //     object.date = date
        //     object.temperaturHigh = temperaturHigh
        //     object.temperaturLow = temperaturLow
        //     object.humidity = humidity
        //     object.pressure = pressure
        //     object.windSpeed = windSpeed
        //     object.visibility = visibility

        //     object.updateCharts()
            
        //   } catch (err) {
        //     console.log(err);
        //   }
        // }

        // getWeather(this, times)
        // console.log("-------------------")
        // console.log(this.temperatureHigh)

        const {format} = require('date-fns');
        


        var dailyDataList = this.getData()['daily']['data']
        dailyDataList.forEach ((dailyData) => {
             date.push(format(new Date(dailyData['time'] * 1000),'dd.MM.yyyy'))
             temperaturHigh.push(Math.round((dailyData['temperatureHigh'] + Number.EPSILON ) * 10 ) / 10)
             temperaturLow.push(Math.round((dailyData['temperatureLow'] + Number.EPSILON ) * 10 ) / 10)
             humidity.push(Math.round(dailyData['humidity'] * 100))
             pressure.push(Math.round(dailyData['pressure']))
             windSpeed.push(Math.round(dailyData['windSpeed']))
             visibility.push(Math.round(dailyData['visibility']))
             console.log('mfamfamfa: ', date);
             console.log('mfamfamfa: ', temperaturHigh);
             console.log('mfamfamfa: ', temperaturLow);
             console.log('mfamfamfa: ', humidity);
             console.log('mfamfamfa: ', pressure);
             console.log('mfamfamfa: ', windSpeed);
             console.log('mfamfamfa: ', visibility);
        }); 
        this.date = date
        this.temperaturHigh = temperaturHigh
        this.temperaturLow = temperaturLow
        this.humidity = humidity
        this.pressure = pressure
        this.windSpeed = windSpeed
        this.visibility = visibility

        console.log('mfamfamfa: ', this.date)
        console.log('mfamfamfa: ', this.temperaturHigh)
        console.log('mfamfamfa: ', this.temperaturLow)
        console.log('mfamfamfa: ', this.humidity)
        console.log('mfamfamfa: ', this.pressure)
        console.log('mfamfamfa: ', this.windSpeed)
        console.log('mfamfamfa: ', this.visibility)
        const today =format(this.picked,'dd.MM.yyyy');
        console.log(today);
        this.updateCharts()
      },
      getData() {
        return {
            "latitude": 47.240609,
            "longitude": 8.92208,
            "timezone": "Europe/Zurich",
            "currently": {
                "time": 1620472495,
                "summary": "Leicht bewölkt",
                "icon": "partly-cloudy-day",
                "precipIntensity": 0,
                "precipProbability": 0,
                "temperature": 16.23,
                "apparentTemperature": 16.23,
                "dewPoint": 7.71,
                "humidity": 0.57,
                "pressure": 1017.1,
                "windSpeed": 1.66,
                "windGust": 3.08,
                "windBearing": 302,
                "cloudCover": 0.37,
                "uvIndex": 6,
                "visibility": 16.093,
                "ozone": 336.8
            },
            "daily": {
        "summary": "Regen am von Dienstag bis nächsten Samstag.",
        "icon": "rain",
        "data": [
            {
                "time": 1620424800,
                "summary": "Den ganzen Tag lang leicht bewölkt.",
                "icon": "partly-cloudy-day",
                "sunriseTime": 1620446280,
                "sunsetTime": 1620499560,
                "moonPhase": 0.91,
                "precipIntensity": 0.0012,
                "precipIntensityMax": 0.0105,
                "precipIntensityMaxTime": 1620424800,
                "precipProbability": 0.02,
                "precipType": "rain",
                "temperatureHigh": 19.36,
                "temperatureHighTime": 1620488460,
                "temperatureLow": 8.05,
                "temperatureLowTime": 1620532320,
                "apparentTemperatureHigh": 19.08,
                "apparentTemperatureHighTime": 1620488460,
                "apparentTemperatureLow": 6.89,
                "apparentTemperatureLowTime": 1620532200,
                "dewPoint": 5.77,
                "humidity": 0.72,
                "pressure": 1018.2,
                "windSpeed": 1.68,
                "windGust": 4.06,
                "windGustTime": 1620511200,
                "windBearing": 156,
                "cloudCover": 0.65,
                "uvIndex": 6,
                "uvIndexTime": 1620473880,
                "visibility": 16.093,
                "ozone": 346.4,
                "temperatureMin": 2.28,
                "temperatureMinTime": 1620444900,
                "temperatureMax": 19.36,
                "temperatureMaxTime": 1620488460,
                "apparentTemperatureMin": 0.4,
                "apparentTemperatureMinTime": 1620444840,
                "apparentTemperatureMax": 19.08,
                "apparentTemperatureMaxTime": 1620488460
            },
            {
                "time": 1620511200,
                "summary": "Den ganzen Tag lang leicht bewölkt.",
                "icon": "partly-cloudy-day",
                "sunriseTime": 1620532620,
                "sunsetTime": 1620586020,
                "moonPhase": 0.94,
                "precipIntensity": 0,
                "precipIntensityMax": 0,
                "precipIntensityMaxTime": 1620540000,
                "precipProbability": 0,
                "temperatureHigh": 25.23,
                "temperatureHighTime": 1620566040,
                "temperatureLow": 12.88,
                "temperatureLowTime": 1620612240,
                "apparentTemperatureHigh": 24.95,
                "apparentTemperatureHighTime": 1620566040,
                "apparentTemperatureLow": 13.15,
                "apparentTemperatureLowTime": 1620612240,
                "dewPoint": 8.68,
                "humidity": 0.63,
                "pressure": 1011.1,
                "windSpeed": 2.33,
                "windGust": 6.55,
                "windGustTime": 1620594240,
                "windBearing": 154,
                "cloudCover": 0.46,
                "uvIndex": 6,
                "uvIndexTime": 1620561120,
                "visibility": 16.093,
                "ozone": 331.1,
                "temperatureMin": 8.05,
                "temperatureMinTime": 1620532320,
                "temperatureMax": 25.23,
                "temperatureMaxTime": 1620566040,
                "apparentTemperatureMin": 6.89,
                "apparentTemperatureMinTime": 1620532200,
                "apparentTemperatureMax": 24.95,
                "apparentTemperatureMaxTime": 1620566040
            },
            {
                "time": 1620597600,
                "summary": "Nieselregen möglich in der Nacht.",
                "icon": "cloudy",
                "sunriseTime": 1620618960,
                "sunsetTime": 1620672480,
                "moonPhase": 0.97,
                "precipIntensity": 0.0147,
                "precipIntensityMax": 0.083,
                "precipIntensityMaxTime": 1620673080,
                "precipProbability": 0.2,
                "precipType": "rain",
                "temperatureHigh": 22.95,
                "temperatureHighTime": 1620641520,
                "temperatureLow": 10.34,
                "temperatureLowTime": 1620709200,
                "apparentTemperatureHigh": 22.67,
                "apparentTemperatureHighTime": 1620641520,
                "apparentTemperatureLow": 10.61,
                "apparentTemperatureLowTime": 1620709200,
                "dewPoint": 9.54,
                "humidity": 0.63,
                "pressure": 1008,
                "windSpeed": 2.32,
                "windGust": 6.68,
                "windGustTime": 1620604920,
                "windBearing": 187,
                "cloudCover": 0.86,
                "uvIndex": 4,
                "uvIndexTime": 1620645240,
                "visibility": 16.093,
                "ozone": 333.4,
                "temperatureMin": 12.2,
                "temperatureMinTime": 1620684000,
                "temperatureMax": 22.95,
                "temperatureMaxTime": 1620641520,
                "apparentTemperatureMin": 12.47,
                "apparentTemperatureMinTime": 1620684000,
                "apparentTemperatureMax": 22.67,
                "apparentTemperatureMaxTime": 1620641520
            },
            {
                "time": 1620684000,
                "summary": "Den ganzen Tag lang Regen.",
                "icon": "rain",
                "sunriseTime": 1620705240,
                "sunsetTime": 1620759000,
                "moonPhase": 1,
                "precipIntensity": 0.4864,
                "precipIntensityMax": 1.2268,
                "precipIntensityMaxTime": 1620756120,
                "precipProbability": 0.92,
                "precipType": "rain",
                "temperatureHigh": 14.72,
                "temperatureHighTime": 1620734760,
                "temperatureLow": 7.73,
                "temperatureLowTime": 1620792120,
                "apparentTemperatureHigh": 14.44,
                "apparentTemperatureHighTime": 1620734760,
                "apparentTemperatureLow": 6.53,
                "apparentTemperatureLowTime": 1620789240,
                "dewPoint": 9.01,
                "humidity": 0.83,
                "pressure": 1010,
                "windSpeed": 1.19,
                "windGust": 5.29,
                "windGustTime": 1620738360,
                "windBearing": 298,
                "cloudCover": 0.97,
                "uvIndex": 4,
                "uvIndexTime": 1620732000,
                "visibility": 14.489,
                "ozone": 346.5,
                "temperatureMin": 9.63,
                "temperatureMinTime": 1620770400,
                "temperatureMax": 14.72,
                "temperatureMaxTime": 1620734760,
                "apparentTemperatureMin": 9.36,
                "apparentTemperatureMinTime": 1620770400,
                "apparentTemperatureMax": 14.44,
                "apparentTemperatureMaxTime": 1620734760
            },
            {
                "time": 1620770400,
                "summary": "Regen bis abends.",
                "icon": "rain",
                "sunriseTime": 1620791580,
                "sunsetTime": 1620845460,
                "moonPhase": 0.03,
                "precipIntensity": 0.7516,
                "precipIntensityMax": 1.8731,
                "precipIntensityMaxTime": 1620798960,
                "precipProbability": 0.99,
                "precipType": "rain",
                "temperatureHigh": 12.53,
                "temperatureHighTime": 1620825360,
                "temperatureLow": 6.54,
                "temperatureLowTime": 1620875340,
                "apparentTemperatureHigh": 12.25,
                "apparentTemperatureHighTime": 1620825360,
                "apparentTemperatureLow": 6.81,
                "apparentTemperatureLowTime": 1620875340,
                "dewPoint": 6.12,
                "humidity": 0.79,
                "pressure": 1014,
                "windSpeed": 1.76,
                "windGust": 7.61,
                "windGustTime": 1620790260,
                "windBearing": 242,
                "cloudCover": 0.95,
                "uvIndex": 4,
                "uvIndexTime": 1620823680,
                "visibility": 12.509,
                "ozone": 372.6,
                "temperatureMin": 7.73,
                "temperatureMinTime": 1620792120,
                "temperatureMax": 12.53,
                "temperatureMaxTime": 1620825360,
                "apparentTemperatureMin": 6.53,
                "apparentTemperatureMinTime": 1620789240,
                "apparentTemperatureMax": 12.25,
                "apparentTemperatureMaxTime": 1620825360
            },
            {
                "time": 1620856800,
                "summary": "Regen in der Nacht.",
                "icon": "cloudy",
                "sunriseTime": 1620877920,
                "sunsetTime": 1620931920,
                "moonPhase": 0.06,
                "precipIntensity": 0.0627,
                "precipIntensityMax": 0.6677,
                "precipIntensityMaxTime": 1620943200,
                "precipProbability": 0.37,
                "precipType": "rain",
                "temperatureHigh": 17.97,
                "temperatureHighTime": 1620915480,
                "temperatureLow": 6.96,
                "temperatureLowTime": 1620962880,
                "apparentTemperatureHigh": 17.69,
                "apparentTemperatureHighTime": 1620915480,
                "apparentTemperatureLow": 5.99,
                "apparentTemperatureLowTime": 1620962280,
                "dewPoint": 4.58,
                "humidity": 0.64,
                "pressure": 1009.8,
                "windSpeed": 1.17,
                "windGust": 2.02,
                "windGustTime": 1620859560,
                "windBearing": 252,
                "cloudCover": 0.95,
                "uvIndex": 4,
                "uvIndexTime": 1620904080,
                "visibility": 16.093,
                "ozone": 354.2,
                "temperatureMin": 6.54,
                "temperatureMinTime": 1620875340,
                "temperatureMax": 17.97,
                "temperatureMaxTime": 1620915480,
                "apparentTemperatureMin": 6.81,
                "apparentTemperatureMinTime": 1620875340,
                "apparentTemperatureMax": 17.69,
                "apparentTemperatureMaxTime": 1620915480
            },
            {
                "time": 1620943200,
                "summary": "Regen am Vormittag und Nachmittag.",
                "icon": "rain",
                "sunriseTime": 1620964200,
                "sunsetTime": 1621018440,
                "moonPhase": 0.09,
                "precipIntensity": 0.6199,
                "precipIntensityMax": 1.9782,
                "precipIntensityMaxTime": 1620951120,
                "precipProbability": 0.86,
                "precipType": "rain",
                "temperatureHigh": 15.49,
                "temperatureHighTime": 1621004760,
                "temperatureLow": 5.77,
                "temperatureLowTime": 1621047600,
                "apparentTemperatureHigh": 15.21,
                "apparentTemperatureHighTime": 1621004760,
                "apparentTemperatureLow": 5.01,
                "apparentTemperatureLowTime": 1621047120,
                "dewPoint": 4.73,
                "humidity": 0.67,
                "pressure": 1011.1,
                "windSpeed": 1.66,
                "windGust": 5.89,
                "windGustTime": 1621014900,
                "windBearing": 282,
                "cloudCover": 0.78,
                "uvIndex": 6,
                "uvIndexTime": 1620991080,
                "visibility": 16.093,
                "ozone": 359.2,
                "temperatureMin": 6.96,
                "temperatureMinTime": 1620962880,
                "temperatureMax": 15.49,
                "temperatureMaxTime": 1621004760,
                "apparentTemperatureMin": 5.99,
                "apparentTemperatureMinTime": 1620962280,
                "apparentTemperatureMax": 15.21,
                "apparentTemperatureMaxTime": 1621004760
            },
            {
                "time": 1621029600,
                "summary": "Nieselregen möglich in der Nacht.",
                "icon": "rain",
                "sunriseTime": 1621050540,
                "sunsetTime": 1621104900,
                "moonPhase": 0.12,
                "precipIntensity": 0.0522,
                "precipIntensityMax": 0.2296,
                "precipIntensityMaxTime": 1621113060,
                "precipProbability": 0.51,
                "precipType": "rain",
                "temperatureHigh": 18.38,
                "temperatureHighTime": 1621086600,
                "temperatureLow": 7.26,
                "temperatureLowTime": 1621134600,
                "apparentTemperatureHigh": 18.1,
                "apparentTemperatureHighTime": 1621086600,
                "apparentTemperatureLow": 6.85,
                "apparentTemperatureLowTime": 1621134240,
                "dewPoint": 4.51,
                "humidity": 0.61,
                "pressure": 1016.7,
                "windSpeed": 1.4,
                "windGust": 2.9,
                "windGustTime": 1621036920,
                "windBearing": 220,
                "cloudCover": 0.92,
                "uvIndex": 4,
                "uvIndexTime": 1621076700,
                "visibility": 15.469,
                "ozone": 372.5,
                "temperatureMin": 5.77,
                "temperatureMinTime": 1621047600,
                "temperatureMax": 18.38,
                "temperatureMaxTime": 1621086600,
                "apparentTemperatureMin": 5.01,
                "apparentTemperatureMinTime": 1621047120,
                "apparentTemperatureMax": 18.1,
                "apparentTemperatureMaxTime": 1621086600
            }                ]
            },
            "offset": 2
        }
      }
    }
  };
</script>

<style scoped>
.history-title {
  font-weight: 500;
  color: rgb(240, 240, 240);
  text-align: center;
  padding-top: 150px;
  margin-bottom: 0px;
  padding-bottom: 80px;
}

.line_chart4, .line_chart5 {
    max-width: 65%;
    padding-left: 8%;
}

.line_chart4 {
    padding-left: 38%;
}

.history {
  margin-bottom: 100px;
}

.picker {
  text-align: center;
  --vdp-selected-bg-color: rgb(30, 144, 255);
  --vdp-hover-bg-color: rgb(30, 144, 255);
  margin-bottom: 20px;
}

.picker-button {
  color: rgb(240, 240, 240);
}

.picker-text {
  color: rgb(240, 240, 240);
  margin-bottom: 10px;
}

</style>